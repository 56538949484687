/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import apiGroupList from '@/apis.json'
import Home from '@/views/Home'
import Help from '@/views/Help'

function route (path, view, name) {
  return {
    name: name || view,
    path,
    component: (resolve) => import(
      '@/views/' + view + '.vue'
    ).then(resolve)
  }
}

Vue.use(Router)

const routesList = []

apiGroupList.forEach(function (group) {
  if (group.items) {
    group.items.forEach(function (api) {
      routesList.push(route(api.path, 'API', api.label))
    })
  }
})

// Create a new router
const router = new Router({
  mode: 'history',

  routes: [
    { path: '*', redirect: '/' },
    { path: '', name: 'Home', component: Home },
    { path: '/help', name: 'Help', component: Help }
  ].concat(routesList),

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

Vue.use(Meta)

export default router
