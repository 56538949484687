<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row class="col-12 text-md-center">
          <v-row class="align-center col-12">
            <v-img contain height="34" src="@/assets/swagger_logo.png"></v-img>
          </v-row>
          <v-row class="align-center col-12">
            <h1 class="full-width">{{ $vuetify.lang.t('$vuetify.homePageTitle') }}</h1>

            <h3 class="full-width mt-10">
              {{ $vuetify.lang.t('$vuetify.homePageExplanationL1') }} <br/>
              {{ $vuetify.lang.t('$vuetify.homePageExplanationL2') }} <br/>
              {{ $vuetify.lang.t('$vuetify.homePageExplanationL3') }} <br/>
            </h3>

          </v-row>
        </v-row>

        <v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Get APIs List

export default {
  name: 'HomePage',
  data: () => ({})
}
</script>

<style scoped>
.full-width {
  width: 100%
}
</style>
