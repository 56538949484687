import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import en from '@/translations/en'
import fr from '@/translations/fr'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: 'en'
  },
  theme: {
    themes: {
      light: {
        primary: '#e8f1e8',
        secondary: '#0a8c1b',
        tertiary: '#495057',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#00d3ee',
        success: '#16a91c',
        warning: '#ffa21a'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
