<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row class="col-12 text-md-center">
          <v-row class="align-center col-12">
            <h1 class="full-width">{{ $vuetify.lang.t('$vuetify.helpFAQ') }}</h1>
            <br/>
            <v-row class="align-center col-12">
              <v-img contain height="100" src="@/assets/logo_swag.png"></v-img>
            </v-row>
          </v-row>
          <v-row class="align-center col-12">
            <v-row justify="center">
              <v-expansion-panels popout>
                <v-expansion-panel v-for="(item,i) in items" :key="i">
                  <v-expansion-panel-header class="orange--text font-weight-bold fs-15 overline">
                    {{ $vuetify.lang.t(item.question) }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col cols="12">
                      <v-card color="#424242" dark>
                        <v-card-text class="fs-15 pa-10" style="text-align: justify;"
                                     v-html="$vuetify.lang.t(item.response)">
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-row>
        </v-row>

        <v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelpPage',
  data: () => ({
    items: [
      {
        question: '$vuetify.helpPageQ1',
        response: '$vuetify.helpPageA1'
      },
      {
        question: '$vuetify.helpPageQ2',
        response: '$vuetify.helpPageA2'
      },
      {
        question: '$vuetify.helpPageQ3',
        response: '$vuetify.helpPageA3'
      },
      {
        question: '$vuetify.helpPageQ4',
        response: '$vuetify.helpPageA4'
      }
    ]
  })
}
</script>

<style scoped>
.full-width {
  width: 100%
}

.v-application .fs-15 {
  font-size: 15px !important;
}

.v-application .fs-12 {
  font-size: 12px !important;
}
</style>
