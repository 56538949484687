import { fr } from 'vuetify/lib/locale'

export default {
  ...fr,
  home: 'Accueil',
  catalog: 'Catalogue',
  help: 'Aide',
  staticData: 'Données statiques',
  references: 'Références OE/AM',
  identification: 'Information véhicule',
  reference: 'API Référence',
  apiCatalog: 'API Catalogue',
  paint: 'API Peinture',
  tire: 'API Pneu',
  vehicle: 'API Véhicule',
  pricing: 'API Tarif',
  maintenance: 'API Maintenance',
  enterAPIKey: 'Pour accéder au swagger de l\'API {0}, veuillez remplir le champs \'Clef API\'.',
  token: 'Clef API',
  swagDirectory: 'Annuaires APIs AutoPass',
  docAPI: 'Documentation APIs',
  selectLanguage: 'Sélectionnez une langue',
  errorGetSwagger: 'Une erreur est survenu lors de la récupération du swagger. Veuillez vérifier la clef API entrée.',
  homePageTitle: '...Toute la documentation des APIs AutoPass...',
  homePageExplanationL1: 'Ici vous trouverez la documentation de toutes les APIs AutoPass sous forme de fichier swagger.',
  homePageExplanationL2: 'Cependant, pour pouvoir y accéder il vous faudra la clef API relative à votre entreprise',
  homePageExplanationL3: 'Seule la documentation des APIs consultables par votre entreprise vous sera accessible.',
  helpFAQ: 'Foire aux questions',
  helpPageQ1: 'Comment accéder à la documentation d\'une API ?',
  helpPageA1: 'Tout d\'abord, regardez le menu sur la gauche. <br> ' +
        ' Choisissez la bonne catégorie pour votre API. <br> ' +
        'Par exemple, si vous recherchez une API liée à un catalogue, choisissez \'Catalogue\'. <br>' +
        'Cliquez ensuite sur le nom de l\'API que vous recherchez. <br/>' +
        'Vous arriverez sur la page de l\'API. <br/>' +
        'Remplissez le champ de texte "Clef API" avec votre clé API et appuyez sur le bouton d\'envoi. <br/>' +
        'Si tout est ok, la documentation apparaîtra, sinon vérifiez votre clé API soit correcte. <br/>',
  helpPageQ2: 'Comment télécharger une collection PostMan ?',
  helpPageA2: 'Après avoir sélectionné la bonne API et entré votre clef.<br/> ' +
        'Un bouton "PostMan" s\'affichera, cliquez dessus.',
  helpPageQ3: 'Où puis-je trouver ma clef API ?',
  helpPageA3: 'Demandez à vos responsables, sinon contactez l\'équipe AutoPass',
  helpPageQ4: 'Pourquoi ai-je une erreur qui s\'affiche quand je souhaite accéder à la documentation ?',
  helpPageA4: ' 1. Il y a sûrement une erreur dans la clef API renseignée, vérifiez qu\'elle corresponde bien à celle qui vous a été fournie. <br>' +
        '2. Vous n\'avez pas accès à l\'API pour laquelle vous demandez la documentation. <br>' +
        '3. La documentation est actuellement indisponible. Contactez alors AutoPass.'

}
