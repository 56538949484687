<template>
  <v-app>

    <core-appbar/>

    <core-view/>

    <core-footer/>

  </v-app>
</template>

<script>
export default {
  components: {
    CoreAppbar: () => import('@/components/core/AppBar'),
    CoreFooter: () => import('@/components/core/Footer'),
    CoreView: () => import('@/components/core/View')
  }
}
</script>
