import { en } from 'vuetify/lib/locale'

export default {
  ...en,
  home: 'Home',
  catalog: 'Catalog',
  catalogDesc: 'API relative to the Catalog',
  help: 'Help',
  staticData: 'Static Data',
  references: 'OE/AM references',
  identification: 'Vehicle info',
  apiCatalog: 'Catalog API',
  reference: 'Reference API',
  paint: 'Paint API',
  tire: 'Tire API',
  vehicle: 'Vehicle API',
  pricing: 'Pricing API',
  maintenance: 'Service API',
  enterAPIKey: 'To access to {0}\'s swagger, please fill token field in header.',
  token: 'Token',
  swagDirectory: 'AutoPass Swagger Directory',
  docAPI: 'API\'s documentation',
  selectLanguage: 'Select language',
  errorGetSwagger: 'Cannot get swagger, please check your token.',
  homePageTitle: '...All AutoPass API documentation...',
  homePageExplanationL1: 'Here you can find the documentation of all AutoPass APIs in swagger format.',
  homePageExplanationL2: 'However, in order to consult it you will need the API key for your company.',
  homePageExplanationL3: 'Only the documentation of the APIs that your company has access to will be available to you.',
  helpFAQ: 'Frequently Asked Questions',
  helpPageQ1: 'How can I get a specific API documentation ?',
  helpPageA1: 'First, check out the menu on the left. <br> ' +
        'Choose the good category for your API. <br> ' +
        'For example, if you looking for an API related to a catalog, choose \'Catalog\'. <br>' +
        'Then click on the name of the API you are looking for. <br/>' +
        'You will arrive on the API page. <br/>' +
        'Fill the "Token" text field with your API key and press on the send button on the right. <br/>' +
        'If everything is ok, the documentation will appear, otherwise check your API key. <br/>',
  helpPageQ2: 'How can I get an API\'s Postman collection ?',
  helpPageA2: 'After selecting the right API and entering your key.<br/>' +
        'A "PostMan" button will appear, click on it.',
  helpPageQ3: 'Where can I found my API key ?',
  helpPageA3: 'Ask your managers, or contact the AutoPass team',
  helpPageQ4: 'Why do I get an error when I want to see a documentation?',
  helpPageA4: '1. There is probably an error in the API key entered, check that it corresponds to the one provided to you. <br>' +
        '2. You do not have access to the API for which you are requesting documentation. <br>' +
        '3. The documentation is currently unavailable. Please contact AutoPass.'
}
